<template>
<div>
    <simplebar data-simplebar ref="messageLogPS" 
    style="height: calc(100vh - 100px);overflow-x: hidden;background-color:#f3f2f0;">
        <ul style="min-height: calc(100vh - 100px);" class="conversation-list py-2 chat-app-conversation" ref="messageLog">
            <li class="clearfix" v-for="(message, index) in messages" :key="index">
                <div class="odd" v-if="isSentUser(message.who, message.sender_id)">
                    <message-content :message="message"  float="right" >
                        <template v-slot:footer-action>
                             <a class="text-success me-2" @click.prevent="initUpdate(message)" href="#">Edit</a>
                             <a class="text-danger" @click.prevent="deleteItem(message.id)" href="#">Delete</a>
                        </template>
                    </message-content>
                </div>
                <div v-else >
                    <message-content :message="message" float="left">
                        <template v-slot:footer-action>
                             <a class="text-success" @click.prevent="initReply(message.id)" href="#">Reply</a>
                        </template>
                    </message-content>
                </div>
            </li>
        </ul>
    </simplebar>
    <slot name="footer"></slot>
    <b-modal centered scrollable id="modal-1" size="sm" v-model="showUpdateModal" :title="isUpdateMode ? 'Update Message' : 'Reply Message'" header-close-variant="primary" title-class="font-18 text-primary" hide-footer>
        <div class="row justify-content-end">
            <div class="col-12 d-flex align-items-center">
                <div class="form-group w-100">
                    <textarea  v-model="onUpdate.message" class="form-control" rows="3" placeholder="Enter something..."></textarea>
                </div>
            </div>
            <div class="col-12">
                <div class="text-center">
                    <button type="button" @click.prevent="isUpdateMode ? updateMessage() : replyMessage()" class="btn btn-primary mt-2">
                    <i class="mdi mdi-content-save"></i> {{isUpdateMode ? 'Update' : 'Reply'}}  </button>
                </div>
            </div>
        </div>
    </b-modal>
</div>
</template>

<script>

import simplebar from "simplebar-vue";
import MessageContent from './MessageContent';

export default {
    components:{
        simplebar,
        MessageContent
    },
    props:{
        messages:{
            type:Array,
            required:true
        }
    },
    data(){
        return {
            onUpdate : {
                message : "",
            },
            isUpdateMode : true,
            showUpdateModal : false
        }
    },
    computed: {
        activeUser() {
            return this.$store.state.activeUser
        },
        buyer(){
            return this.$store.state.orderList.order.buyer
        },
        isSentUser(){
            return (who, senderId) => who == 'buyer' || senderId == this.activeUser.id;
        },
    },
    methods: {
        scrollMessageAreaUp(){
            setTimeout(()=>{
                const scrollH = this.$refs.messageLog.scrollHeight
                const scrolEl = this.$refs.messageLogPS.SimpleBar.getScrollElement();
                scrolEl.scrollTop = scrollH;
            },200)
        },
        initUpdate(msg){
            this.isUpdateMode = true;
            this.onUpdate = {
                message: msg.message,
                id: msg.id,
            }
            this.showUpdateModal = true

        },
        initReply(msgId){
            this.isUpdateMode = false;
            this.onUpdate = {
                message: "",
                id: msgId,
            }
            this.showUpdateModal = true

        },
        updateMessage() {
            if (!this.onUpdate.message) { this.alertError("Please type something"); return;}
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.onUpdate))
            this.requestProcessor(formData, `${this.onUpdate.id}/update`, "UPDATE")
        },
        replyMessage() {
            if (!this.onUpdate.message) { this.alertError("Please type something"); return;}
            const formData = new FormData()
            formData.append('data', JSON.stringify({'reply': this.onUpdate.message}))
            this.requestProcessor(formData, `${this.onUpdate.id}/reply`, "UPDATE")
        },
        requestProcessor(formData, url, action ='ADD'){
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/order-messages/${url}`,formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.$store.commit(`orderList/${action}_MESSAGE`, response.data.data)
                    this.resetForm();
                }
            })
        },
        deleteItem(itemId){
            Swal.fire({
                title: "Are you sure?",
                text: "You are about to delete this message!",
                reverseButtons:true,
                showCancelButton: true,
                confirmButtonColor: "#e11",
                cancelButtonColor: "#111",
                confirmButtonText: "Delete it!",
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch("changeLoaderValue", true)
                    this.$http.delete(`/order-messages/${itemId}/delete`)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("orderList/DELETE_MESSAGE", itemId)
                        }
                    })
                }
            });
        },
        resetForm(){
            this.onUpdate = {
                message: ""
            }
            this.showUpdateModal = false
            this.isUpdateMode = true;
        },
    }

};
</script>

<style lang="scss">
.simplebar-placeholder{
    display: none !important;
}
.conversation-list {
    list-style: none;
    padding: 0 15px;
    li {
        margin-bottom: 20px;
        .conversation-actions {
            float: right;
            display: none;
        }
        &:hover {
            .conversation-actions {
                display: block;
            }
        }
        .conversation-lists{
            display: inline-block;
            position: relative;
        }
    }
    .border-bottom{
        border-color: #fff !important;
    }
    .chat-avatar {
        float: left;
        text-align: center;
        width: 42px;
        img {
            border-radius: 100%;
            width: 100%;
        }
        i {
            font-size: 12px;
            font-style: normal;
        }
    }
    .ctext-wrap {
        background: #fff;
        border-radius: 3px;
        display: inline-block;
        padding: 12px;
        position: relative;
        i {
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            position: relative;
        }
        p {
            margin: 0;
            padding-top: 3px;
        }
        &:after {
            left: -10px;
            top: 0;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-top-color: #fff;
            border-width: 6px;
            margin-right: -1px;
            border-right-color: #fff;
        }
    }
    .conversation-text {
        float: left;
        font-size: 12px;
        margin-left: 12px;
        width: 70%;
    }
    .odd {
        color: #111b21;
        a,p{
         color: #111b21;
        }
        .chat-avatar {
            float: right !important;
        }
        .conversation-text {
            float: right !important;
            margin-right: 12px;
            text-align: right;
            width: 70%;
        }
        .ctext-wrap {
            background-color: #d9fdd3;
            &:after {
                border-color: transparent;
                border-left-color: #d9fdd3;
                border-top-color: #d9fdd3;
                right: -10px !important;
                left: auto;
            }
        }
        .conversation-actions {
            float: left;
        }
    }
}
</style>